/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { lazy } from "react";
//Custom libraries
import * as ROUTES from "../constants/routes";
import { RouteConfig } from "react-router-config";
import ContactUs from "../components/pages/static_components/ContactUs";
import WarrantyInformation from "../components/pages/static_components/WarrantyInformation";
import ShippingAndDelivery from "../components/pages/static_components/ShippingAndDelivery";
import Return from "../components/pages/static_components/Return";
import tnc from "../components/pages/static_components/tnc";
import { AfterLogin } from "../components/pages/afterLogin/AfterLogin";
import MakeReturn from "../components/pages/static_components/makeReturn";
import OrderReturnLabelSlip from "../components/pages/static_components/orderLabelnSlip";
import SiteMap from "../components/pages/static_components/siteMap";
import { AfterLogout } from "../components/pages/afterLogin/AfterLogout";

//Common pages
const SEO = lazy(() => import("../_foundation/seo/SEO"));

//component
const SignIn = lazy(() => import("../components/pages/sign-in/SignIn"));
const ForgotPassword = lazy(
  () => import("../components/pages/forgot-password/ForgotPassword")
);
const MyGarage = lazy(() => import("../components/MyAccount/MyGarage"));
const AddNewVehicle = lazy(() => import("../components/MyAccount/AddVehicle"));
const AddressBook = lazy(
  () => import("../components/MyAccount/AccountDetails")
);
const EditAddress = lazy(
  () => import("../components/pages/address-book/EditAddress")
);
const AddAddress = lazy(
  () => import("../components/pages/address-book/AddAddress")
);
const SearchResults = lazy(
  () => import("../components/pages/search-results/SearchResults")
);

//checkout
const Shipping = lazy(
  () => import("../components/pages/checkout/shipping/Shipping")
);
const CheckoutRewardsDiscount = lazy(() => import("../components/widgets/checkout-rewards-discount/checkout-rewards-discount"));
const Billing = lazy(() => import("../components/widgets/checkout-payment"));

const AfterPayment = lazy(
  () => import("../components/widgets/checkout-payment/after-payment")
);

const Review = lazy(() => import("../components/widgets/checkout-review"));
const CheckoutProfiles = lazy(
  () => import("../components/pages/checkout-profile/CheckoutProfiles")
);
 

//Sapphire pages
const Dashboard = lazy(
  () => import("../components/pages/_sapphire/dashboard/Dashboard")
);
const Account = lazy(() => import("../components/MyAccount/DashBoard"));

const RecurringOrders = lazy(
  () => import("../components/pages/_sapphire/order/RecurringOrdersPage")
);
const OrderDetailsPage = lazy(
  () => import("../components/pages/_sapphire/order/OrderDetailsPage")
);
const OrderHistoryPage = lazy(
  () => import("../components/MyAccount/OrderHistory")
);
const OrderDetails = lazy(() => import("../components/MyAccount/OrderDetails"));
const AccountSummary = lazy(
  () => import("../components/pages/_sapphire/account-summary/AccountSummary")
);
const BuyerUserRegistration = lazy(
  () =>
    import(
      "../components/pages/_sapphire/buyer-user-registration/BuyerUserRegistration"
    )
);
const BuyerOrganizationRegistration = lazy(
  () =>
    import(
      "../components/pages/_sapphire/buyer-organization-registration/BuyerOrganizationRegistration"
    )
);
const AdminTools = lazy(
  () => import("../components/pages/_sapphire/adminTools/AdminTools")
);
const CheckoutProfileCreate = lazy(
  () => import("../components/pages/checkout-profile/CheckoutProfileCreate")
);
const InprogressOrderDetailsPage = lazy(
  () =>
    import("../components/pages/_sapphire/order/inprogress-order-details-page")
);

const InprogressOrders = lazy(
  () => import("../components/pages/_sapphire/order/inprogress-orders")
);

const Faq = lazy(() => import("../components/pages/static_components/Faq"));

//wish List
const WishListView = lazy(
  () => import("../components/pages/wish-list/wishlist-view")
);

const LearnMoreRewards = lazy(
  () => import("../components/pages/static_components/LearnMoreRewards")
);
const CheckoutRouteConfig: RouteConfig[] = [
  //New routes config
  {
    key: ROUTES.CHECKOUT_INFORMATION,
    path: ROUTES.CHECKOUT_INFORMATION,
    component: Shipping,
  },
  {
    key: ROUTES.CHECKOUT_INFORMATION_OLD,
    path: ROUTES.CHECKOUT_INFORMATION_OLD,
    component: Shipping,
  },
  {
    key: ROUTES.CHECKOUT_SHIPPING,
    path: ROUTES.CHECKOUT_SHIPPING,
    component: Shipping,
  },
  {
    key: ROUTES.CHECKOUT_SHIPPING_OLD,
    path: ROUTES.CHECKOUT_SHIPPING_OLD,
    component: Shipping,
  },
   {
    key: ROUTES.CHECKOUT_REWARDS,
    path: ROUTES.CHECKOUT_REWARDS,
    component: CheckoutRewardsDiscount,
  },
  {
    key: ROUTES.CHECKOUT_PAYMENT,
    path: ROUTES.CHECKOUT_PAYMENT,
    component: Billing,
  },
  {
    key: ROUTES.CHECKOUT_PAYMENT_OLD,
    path: ROUTES.CHECKOUT_PAYMENT_OLD,
    component: Billing,
  },
  {
    key: ROUTES.CHECKOUT_REVIEW,
    path: ROUTES.CHECKOUT_REVIEW,
    component: Review,
  },
  {
    key: ROUTES.CHECKOUT_AFTER_PAYMENT,
    path: ROUTES.CHECKOUT_AFTER_PAYMENT,
    component: AfterPayment,
  },
];

const B2BRouteConfig: RouteConfig[] = [
  {
    key: ROUTES.DASHBOARD,
    path: ROUTES.DASHBOARD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: Dashboard,
  },
  {
    key: ROUTES.PERSONAL_INFORMATION,
    path: ROUTES.PERSONAL_INFORMATION,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AccountSummary,
  },

  {
    key: ROUTES.SIGNIN,
    path: ROUTES.SIGNIN,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    component: SignIn,
  },
  {
    key: ROUTES.FORGOT_PASSWORD,
    path: ROUTES.FORGOT_PASSWORD,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    component: ForgotPassword,
  },
  {
    key: ROUTES.ORG_REGISTRATION,
    path: ROUTES.ORG_REGISTRATION,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    component: BuyerOrganizationRegistration,
  },
  {
    key: ROUTES.BUYER_REGISTRATION,
    path: ROUTES.BUYER_REGISTRATION,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    component: BuyerUserRegistration,
  },
  {
    key: ROUTES.CHECKOUT_PROFILE_CREATE,
    path: ROUTES.CHECKOUT_PROFILE_CREATE,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: CheckoutProfileCreate,
  },
  {
    key: ROUTES.CHECKOUT_PROFILE_EDIT,
    path: ROUTES.CHECKOUT_PROFILE_EDIT,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: CheckoutProfileCreate,
  },
  {
    key: ROUTES.CHECKOUT_PROFILES,
    path: ROUTES.CHECKOUT_PROFILES,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: CheckoutProfiles,
  },
  {
    key: ROUTES.SEARCH,
    path: ROUTES.SEARCH,
    exact: true,
    component: SearchResults,
  },
  {
    key: ROUTES.ORDER_HISTORY,
    path: ROUTES.ORDER_HISTORY,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: OrderHistoryPage,
  },
  {
    key: ROUTES.RECURRING_ORDERS,
    path: ROUTES.RECURRING_ORDERS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: RecurringOrders,
  },
  {
    key: ROUTES.ORDER_DETAILS,
    path: ROUTES.ORDER_DETAILS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: OrderDetailsPage,
  },
  {
    key: ROUTES.ADDRESS_BOOK,
    path: ROUTES.ADDRESS_BOOK,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AddressBook,
  },
  {
    key: ROUTES.ADDRESS_BOOK_OLD,
    path: ROUTES.ADDRESS_BOOK_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AddressBook,
  },
  {
    key: ROUTES.EDIT_ADDRESS,
    path: ROUTES.EDIT_ADDRESS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: EditAddress,
  },
  {
    key: ROUTES.ADD_ADDRESS,
    path: ROUTES.ADD_ADDRESS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AddAddress,
  },
  {
    key: ROUTES.ORGANIZATION_MANAGEMENT,
    path: ROUTES.ORGANIZATION_MANAGEMENT,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AdminTools,
  },
  {
    key: ROUTES.BUYER_MANAGEMENT,
    path: ROUTES.BUYER_MANAGEMENT,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AdminTools,
  },
  {
    key: ROUTES.APPROVALS_MANAGEMENT,
    path: ROUTES.APPROVALS_MANAGEMENT,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AdminTools,
  },
  {
    key: ROUTES.ORDER_APPROVAL,
    path: ROUTES.ORDER_APPROVAL,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AdminTools,
  },
  {
    key: ROUTES.IP_ORDER_DETAILS,
    path: ROUTES.IP_ORDER_DETAILS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: InprogressOrderDetailsPage,
  },
  {
    key: ROUTES.INPROGRESS_ORDERS,
    path: ROUTES.INPROGRESS_ORDERS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: InprogressOrders,
  },
  {
    key: ROUTES.INPROGRESS_ORDERS,
    path: ROUTES.INPROGRESS_ORDERS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: OrderDetails,
  },
  {
    key: "other",
    path: "/*",
    exact: true,
    component: SEO,
  },
];
const B2CRouteConfig: RouteConfig[] = [
  {
    key: ROUTES.SIGNIN,
    path: ROUTES.SIGNIN,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    component: SignIn,
  },
  {
    key: ROUTES.FORGOT_PASSWORD,
    path: ROUTES.FORGOT_PASSWORD,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    component: ForgotPassword,
  },
  {
    key: ROUTES.CHECKOUT_PROFILE_CREATE,
    path: ROUTES.CHECKOUT_PROFILE_CREATE,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: CheckoutProfileCreate,
  },
  {
    key: ROUTES.CHECKOUT_PROFILE_EDIT,
    path: ROUTES.CHECKOUT_PROFILE_EDIT,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: CheckoutProfileCreate,
  },
  {
    key: ROUTES.CHECKOUT_PROFILES,
    path: ROUTES.CHECKOUT_PROFILES,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: CheckoutProfiles,
  },
  {
    key: ROUTES.ACCOUNT,
    path: ROUTES.ACCOUNT,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: Account,
  },
  {
    key: ROUTES.ACCOUNT_OLD,
    path: ROUTES.ACCOUNT_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: Account,
  },
  {
    key: ROUTES.CONTACT,
    path: ROUTES.CONTACT,
    exact: true,
    //isProtected: ROUTES.REGISTER_PROTECTED,
    component: ContactUs,
  },
  {
    key: ROUTES.CONTACT_OLD,
    path: ROUTES.CONTACT_OLD,
    exact: true,
    //isProtected: ROUTES.REGISTER_PROTECTED,
    component: ContactUs,
  },
  {
    key: ROUTES.WARRANTY_INFORMATION,
    path: ROUTES.WARRANTY_INFORMATION,
    exact: true,
    //isProtected: ROUTES.REGISTER_PROTECTED,
    component: WarrantyInformation,
  },
  {
    key: ROUTES.WARRANTY_INFORMATION_OLD,
    path: ROUTES.WARRANTY_INFORMATION_OLD,
    exact: true,
    //isProtected: ROUTES.REGISTER_PROTECTED,
    component: WarrantyInformation,
  },
  {
    key: ROUTES.LEARNMOREREWARDS,
    path: ROUTES.LEARNMOREREWARDS,
    component: LearnMoreRewards,
  },
  {
    key: ROUTES.LEARNMOREREWARDS_OLD,
    path: ROUTES.LEARNMOREREWARDS_OLD,
    component: LearnMoreRewards,
  },
  {
    key: ROUTES.MY_GARAGE,
    path: ROUTES.MY_GARAGE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: MyGarage,
  },
  {
    key: ROUTES.MY_GARAGE_OLD,
    path: ROUTES.MY_GARAGE_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: MyGarage,
  },
  {
    key: ROUTES.ADD_NEW_VEHICLE,
    path: ROUTES.ADD_NEW_VEHICLE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AddNewVehicle,
  },
  {
    key: ROUTES.EDIT_NEW_VEHICLE,
    path: ROUTES.EDIT_NEW_VEHICLE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AddNewVehicle,
  },
  {
    key: ROUTES.SEARCH,
    path: ROUTES.SEARCH,
    exact: true,
    component: SearchResults,
  },
  {
    key: ROUTES.SEARCH_OLD,
    path: ROUTES.SEARCH_OLD,
    exact: true,
    component: SearchResults,
  },
  {
    key: ROUTES.ADDRESS_BOOK,
    path: ROUTES.ADDRESS_BOOK,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AddressBook,
  },
  {
    key: ROUTES.EDIT_ADDRESS,
    path: ROUTES.EDIT_ADDRESS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: EditAddress,
  },
  {
    key: ROUTES.ADD_ADDRESS,
    path: ROUTES.ADD_ADDRESS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AddAddress,
  },
  {
    key: ROUTES.ORDER_HISTORY,
    path: ROUTES.ORDER_HISTORY,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: OrderHistoryPage,
  },
  {
    key: ROUTES.ORDER_HISTORY_OLD,
    path: ROUTES.ORDER_HISTORY_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: OrderHistoryPage,
  },
  {
    key: ROUTES.ORDER_DETAILS,
    path: ROUTES.ORDER_DETAILS_ROUTE,
    exact: true,
    // isProtected: ROUTES.REGISTER_PROTECTED,
    component: OrderDetails,
  },
  {
    key: ROUTES.IP_ORDER_DETAILS,
    path: ROUTES.IP_ORDER_DETAILS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: InprogressOrderDetailsPage,
  },
  {
    key: ROUTES.INPROGRESS_ORDERS,
    path: ROUTES.INPROGRESS_ORDERS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: InprogressOrders,
  },
  {
    key: ROUTES.FAQ,
    path: ROUTES.FAQ,
    component: Faq,
  },
  {
    key: ROUTES.FAQ_OLD,
    path: ROUTES.FAQ_OLD,
    component: Faq,
  },
  {
    key: ROUTES.RETURN,
    path: ROUTES.RETURN,
    component: Return,
  },
  {
    key: ROUTES.RETURN_OLD,
    path: ROUTES.RETURN_OLD,
    component: Return,
  },
  {
    key: ROUTES.MAKERETURN,
    path: ROUTES.MAKERETURN,
    component: MakeReturn,
  },
  {
    key: ROUTES.MAKERETURN_OLD,
    path: ROUTES.MAKERETURN_OLD,
    component: MakeReturn,
  },
  {
    key: ROUTES.RETURNSLIP,
    path: ROUTES.RETURNSLIP,
    component: OrderReturnLabelSlip,
  },
  {
    key: ROUTES.RETURNSLIP_OLD,
    path: ROUTES.RETURNSLIP_OLD,
    component: OrderReturnLabelSlip,
  },
  {
    key: ROUTES.SHIPPING_DELIVERY,
    path: ROUTES.SHIPPING_DELIVERY,
    component: ShippingAndDelivery,
  },
  {
    key: ROUTES.SHIPPING_DELIVERY_OLD,
    path: ROUTES.SHIPPING_DELIVERY_OLD,
    component: ShippingAndDelivery,
  },
  {
    key: ROUTES.TNC,
    path: ROUTES.TNC,
    component: tnc,
  },
  {
    key: ROUTES.TNC_OLD,
    path: ROUTES.TNC_OLD,
    component: tnc,
  },
  {
    key: ROUTES.REDIRECT_URL_AFTER_LOGIN,
    path: ROUTES.REDIRECT_URL_AFTER_LOGIN,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AfterLogin,
  },
  {
    key: ROUTES.REDIRECT_URL_AFTER_LOGOUT,
    path: ROUTES.REDIRECT_URL_AFTER_LOGOUT,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AfterLogout,
  },
  {
    key: ROUTES.WISH_LIST,
    path: ROUTES.WISH_LIST,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: WishListView,
  },
  {
    key: ROUTES.SITEMAP,
    path: ROUTES.SITEMAP,
    component: SiteMap,
  },
  {
    key: ROUTES.SITEMAP_OLD,
    path: ROUTES.SITEMAP_OLD,
    component: SiteMap,
  },
  {
    key: "other",
    path: "/*",
    exact: true,
    component: SEO,
  },
];
export const ROUTE_CONFIG = {
  B2B: B2BRouteConfig,
  B2C: B2CRouteConfig,
  Checkout: CheckoutRouteConfig,
};
