/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020, 2021
 *
 *==================================================
 */
//Standard libraries
import React, { useState } from "react"; 
import { useSite } from "../../_foundation/hooks/useSite";
import eSpotService from "../../_foundation/apis/transaction/eSpot.service";
import { EMPTY_STRING } from "../../constants/common";
import HTMLReactParser from "html-react-parser";

const DisclosureEspot: React.FC = (props: any) => {
  const { mySite } = useSite();
  const storeID: string = mySite ? mySite.storeID : EMPTY_STRING;
  const catalogID: string = mySite ? mySite.catalogID : EMPTY_STRING;
  const [disclosureHtml, setDisclosureHtml] = useState("");
  const initMarketingContent = () => {
    const parameters: any = {
      storeId: storeID,
      name: "Lincoln_Disclosure_Espot",
      catalogId: catalogID,
      widget: "e-marketing-spot-widget",
      query: {
        contentName: "Lincoln_DisclosureContent",
      },
    };
    eSpotService
      .findByName(parameters)
      .then((res) => {
        const eSpotRoot =
          res.data.MarketingSpotData[0].baseMarketingSpotActivityData[0];
        if (eSpotRoot?.marketingContentDescription[0]?.marketingText) {
          setDisclosureHtml(eSpotRoot?.marketingContentDescription[0]?.marketingText);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  React.useEffect(() => {
    if (mySite) {
      initMarketingContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite]);

  return <div>{HTMLReactParser(disclosureHtml)}</div>;
};

export default DisclosureEspot;
